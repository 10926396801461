<template>
    <div class="main">
        <div class="container links">
            <router-link to="/">Bosh sahifa</router-link> >
            <router-link to="/terms-of-use">Foydalanish shartlari</router-link>
        </div>
        <div class="container privacy-policy">
            <h2>Foydalanish shartlari</h2>
            <p>1. Термины и определения.
                1.1. В настоящей оферте, если контекст не требует иного, нижеприведенные термины имеют следующие значения и
                являются ее составной неотъемлемой частью:
                Продавец — ООО «OSIYO KABEL» Узбекистан, г.Ташкент, ул.Уста Ширин, д. 125Е
                (р/с 2020 8000 3043 9727 3001 в АТИБ Ипотека Банк Алмазарский ф-л г. Ташкент МФО 00901 ИНН 205 570 304, ОКЭД
                27320)

                <br>
                Покупатель — любое физическое или юридическое лицо, акцептировавшее публичную оферту на условиях настоящей
                оферты.
                <br>

                Интернет-магазин — официальный интернет-магазин Продавца, расположенный по интернет адресу
                http://www.rahmat.uz. В рамках настоящего договора, понятия Интернет-магазин и Магазин, а также интернет
                адреса http://www.rahmat.uz и производные от http://www.rahmat.uz являются равносильными и трактуются
                аутентично, по контексту оферты.
                <br>

                Товар — объект соглашения сторон, перечень наименований ассортимента, представленный в официальном
                интернет-магазине Продавца по интернет адресу http://www. rahmat.uz.
                Заказ — электронная заявка, сформированная на сайте http://www. rahmat.uz, содержащая ID покупателя,
                перечень, количество, цену товаров и другие данные.
                Доставка — курьерские услуги по доставке Товара Покупателю.
                Акция (Программа) — мероприятие Продавца, направленное на реализацию (распродажу) Товаров по особым
                условиям.
                Оператор — лицо, принимающее заказы Покупателя через, телеграмм бот или же по номеру +99878 120-0909.
                <br>
                2. Общие положения.
                <br>

                2.1. Настоящая публичная Оферта (в дальнейшем — Договор) является официальным предложением Продавца в адрес
                любого физического или юридического лица, обладающего дееспособностью и необходимым полномочием заключить с
                Продавцом договор купли-продажи Товара на условиях, определенных в настоящей оферте, содержит все
                существенные условия договора и имеет юридическую силу, в том числе на период проводимых различных Акций
                (Программ) Продавца.<br>
                2.2. В случае, если Акции (Программы) содержат иные условия чем те, которые отражены в настоящей Оферте, то
                условия Акций и Программ в период их действия имеют преимущество.
                <br>
                3. Предмет Договора и цена Товара.<br>
                3.1. Продавец передает, а Покупатель принимает и оплачивает товары на условиях, указанных в настоящем
                договоре. Право собственности на Заказанные Товары у Покупателя возникает с момента фактической передачи
                Товара Покупателю и оплаты последним полной стоимости Товара. Риск случайной гибели или повреждения Товара
                переходит к Покупателю с момента фактической передачи Товара Покупателю.
                <br>
                3.2. Цены на Товар определяются в одностороннем бесспорном порядке и указываются на страницах
                интернет-магазина по интернет-адресу: http://www.rahmat.uz.<br>
                3.3. Цена товара указывается в национальной валюте Республики Узбекистан — сум.<br>
                3.4. Предложение о заключении договора на конкретный товар действует в течение срока нахождения товара на
                интернет-сайте Продавца.<br>
                3.5. Товар представляется на сайте через графические изображения-образцы, являющиеся собственностью
                интернет-магазина.<br>
                3.6. В связи с разными техническими характеристиками мониторов цвет Товара может отличаться от цвета Товара,
                представленного на сайте.<br>
                3.7. Внешний вид Товара может отличаться от описанного на сайте.<br>
                3.8. По просьбе Покупателя Менеджер интернет-магазина обязан предоставить (по телефону или посредством
                электронной почты) Покупателю необходимую информацию для принятия им решения о покупке товара.<br>
                3.9. Количество товаров, представленных на страницах интернет-магазина по интернет-адресу:
                http://www.rahmat.uz может быть ограничено и в случае отсутствия товара по прибытию Покупателя в офис продаж
                ему возвращаются денежные средства, оплаченные за товар или по его желанию и выбору предоставляется другой
                товар. При предоставлении Покупателю товара в случае, если цена данного товара ниже цены оплаченного товара,
                разница в цене возвращается Покупателю, в случае, если цена данного товара выше цены оплаченного товара,
                Покупатель должен доплатить разницу в ценах.
                <br>
                4. Момент заключения договора.<br>
                4.1. Текст данного Договора является публичной офертой (в соответствии с частью 2 ст. 369 и ст. 426
                Гражданского кодекса Республики Узбекистан).<br>
                4.2. Договор, заключаемый на основании акцептирования Покупателем настоящей оферты является договором
                присоединения, к которому Покупатель присоединяется без каких-либо исключений и/или оговорок.
                <br> 4.3. Факт оформления заказа Покупателем является безоговорочным фактом принятия Покупателем условий
                данного
                Договора. Покупатель, приобретший товар в Интернет-магазине Продавца (оформивший заказ товара),
                рассматривается как лицо, вступившее с Продавцом в отношения на условиях настоящего Договора.

                <br>5. Права и обязанности сторон.
                <br> 5.1. Обязанности Продавца:
                <br> 5.1.1. С момента заключения настоящего Договора обеспечить в полной мере все обязательства перед
                Покупателем
                в соответствии с условиями настоящего договора и действующего законодательства. Продавец оставляет за собой
                право невыполнения обязательств по Договору в случае возникновения обстоятельств непреодолимой силы,
                указанных в п.9 настоящего Договора.
                <br> 5.1.2. Обрабатывать персональные данные Покупателя и обеспечивать их конфиденциальность в порядке,
                установленном действующим законодательством.
                <br> 5.1.3. Предотвращать попытки несанкционированного доступа к информации и/или передачу ее лицам, не
                имеющим
                непосредственного отношения к исполнению Заказов; своевременно обнаруживать и пресекать такие факты.
                <br> 5.2. Продавец имеет право:
                <br> 5.2.1. Изменять настоящий Договор, Цены на Товар и Тарифы на сопутствующие услуги, способы и сроки
                оплаты и
                доставки товара в одностороннем порядке, помещая их на страницах интернет-магазина, расположенного по
                интернет-адресу: http://www.rahmat.uz. Все изменения вступают в силу немедленно после публикации, и
                считаются доведенными до сведения Покупателя с момента такой публикации.
                <br> 5.2.2. Ставить ограничения на объемы/количество Товаров при одном Заказе, изменять сумму
                Заказа/Доставки при
                определенных условиях (объем/количество Товара, радиус диапазона доставки, расстояние, трудности доставки
                и.т.д).
                <br> 5.2.3. Осуществлять записи телефонных переговоров с Покупателем.
                <br> 5.2.4. Без согласования с Покупателем, передавать свои права и обязанности по исполнению Договора
                третьим
                лицам.
                <br>5.3. Покупатель обязуется:
                <br>5.3.1. До момента заключения Договора ознакомиться с содержанием и условиями Договора, ценами на Товар,
                предлагаемыми Продавцом в интернет-магазине, а также другими условиями на страницах интернет-магазина,
                расположенного по интернет-адресу: http://www.rahmat.uz.
                <br>5.3.2. Во исполнение Продавцом своих обязательств перед Покупателем сообщить все необходимые данные,
                идентифицирующие его как покупателя, и достаточные для доставки Покупателю заказанного им Товара при условии
                доставки товара.
                <br> 5.3.3. Оплатить заказанный Товар и его доставку на условиях настоящего договора.
                <br>5.3.4. Во избежание споров, при оформлении заказа ознакомиться с информацией, предложенной Продавцом на
                своем интернет-сайте.

                <br>6. Доставка товара
                <br>6.1. Доставка Товара Покупателю осуществляется на условиях, указанных в разделе «Оплата заказа» и
                «Доставка», а также в соответствие с пункт 6 настоящей Оферты.
                <br> 6.2. Доставка товаров осуществляется по городу Ташкент. Сумма доставки является неотъемлемой частью
                Оферты и
                прописывается при оформлении заказа Покупателем в личном кабинете.
                <br>6.3. Время доставки Товара указывается в Личном кабинете Покупателя при оформлении заказа. В
                исключительных
                случаях доставка товаров может быть осуществлена в течение 48 часов с момента истечения срока доставки
                заказа, указанного в личном кабинете.
                <br> 6.4. При отказе Покупателя от Товара или его части в момент доставки, Покупателем оплачивается
                стоимость
                доставки и возврата Товара в магазин, за исключением случаев, когда в процессе приемки Товара обнаружится,
                что по вине Продавца Покупателю поставлен Товар ненадлежащего качества (количество, ассортимент или
                комплектность).
                <br>6.5. Доставка считается исполненной c момента получения Товара Покупателем.
                <br> 6.6. При получении Товара Покупатель обязан осмотреть Товар на соответствие заявки (количество,
                ассортимент
                и комплектность), а также проверить его срок годности.
                <br> 6.7. Доставленный Товар передается Покупателю, а при отсутствии Покупателя - любому лицу, указанному
                Покупателем в качестве Получателя Товара. При передаче Товара Курьер, в целях предотвращения случаев
                мошенничества, имеет право потребовать документы, удостоверяющие личность получателя Товара.

                <br>7. Условия возврата и обмена товаров
                <br>7.1. Товары надлежащего качества.
                <br>7.1.1. Покупатель вправе отказаться от заказанного товара надлежащего качества без объяснения причины в
                любое время: до его получения, в момент доставки товара курьером, а также после получения — в течение 24
                часов.
                <br>7.1.2. Возврат или обмен товара возможны, когда: сохранены его товарный вид (упаковка, пломбы, ярлыки),
                потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара
                (товарный или кассовый чек).
                <br>7.1.3. Возврату не подлежат товары надлежащего качества, согласно Приложения 1 к Правилам розничной
                торговли
                Республики Узбекистан, утвержденное Постановлением Кабинета Министров Республики Узбекистан № 75 от 13
                февраля 2003 года.
                <br>7.1.4. Возврат товара осуществляется посредством «Служба доставки» Продавца, для этого необходимо:
                <br>
                · позвонить в интернет-магазин по телефонам, указанным в разделе «Контакты»;
                <br>
                · распечатать и заполнить «Заявление на возврат»;
                <br>
                · приложить к заявлению на возврат товарный или кассовый чек заказа.
                <br>
                <br> 7.2. Товары ненадлежащего качества.
                <br>7.2.1. Покупатель, при получении товара ненадлежащего качества, если недостатки не были оговорены
                Продавцом,
                вправе по своему выбору потребовать от Продавца (в отношении продовольственных товаров, покупатель имеет
                данное право в течение 24 часов после получения):
                <br>
                · замены на товар аналогичной марки (модели, артикула);
                <br>
                · замены на такой же товар другой марки (модели, артикула) с соответствующим перерасчетом покупной цены;
                <br>
                · соразмерного уменьшения покупной цены;
                <br>
                · незамедлительного безвозмездного устранения недостатков товара;
                <br>
                · возмещения расходов на устранение недостатков товаров.
                <br>
                <br> 7.2.2. Процедура возврата товара ненадлежащего качества осуществляется способом, указанным в п.7.1.4.
                <br> 7.2.3. В заявлении на возврат должно быть указано, какой именно дефект содержит возвращаемый
                Покупателем
                товар.
                <br>
                <br> 8. Способы оплаты.
                <br>8.1. Произвести оплату Покупатель может следующими способами:

                <br>· Оплата банковской пластиковой картой (ONLINE);

                <br> · Оплата через системы CLICK, Payme дополнить ;

                <br> · Оплата наличными средствами.

                <br> 8.2. Более подробная информация по способам оплаты товара содержится в разделе интернет-сайта «Условия
                доставки», расположенного по интернет адресу http://www.rahmat.uz.

                <br> 9. Форс-мажор.
                <br> 9.1. Любая из Сторон освобождается от ответственности за полное или частичное неисполнение своих
                обязательств по настоящему Договору, если это неисполнение было вызвано обстоятельствами непреодолимой силы,
                возникшими после подписания настоящего Договора. «Обстоятельства Непреодолимой Силы» означают чрезвычайные
                события или обстоятельства, которые такая Сторона не могла предвидеть или предотвратить доступными ей
                средствами. Такие чрезвычайные события или обстоятельства включают в себя, в частности: забастовки,
                наводнения, пожары, землетрясения и иные стихийные бедствия, войны, военные действия, действия
                государственных органов Республики Узбекистан или иных государств, а также любые иные обстоятельства,
                выходящие за пределы разумного контроля любой из Сторон. Изменения действующего законодательства или
                нормативных актов, прямо или косвенно влияющие на какую-либо из Сторон, не рассматриваются как
                Обстоятельства непреодолимой силы, однако, в случае внесения таких изменений, которые не позволяют любой из
                Сторон исполнить какие-либо из ее обязательств по настоящему Договору, Стороны обязаны незамедлительно
                принять решение относительно порядка работы по устранению этой проблемы с тем, чтобы обеспечить Сторонам
                продолжение исполнения настоящего Договора.

                <br> 10. Ответственность сторон.
                <br> 10.1. За неисполнение или ненадлежащее исполнение условий настоящего Договора Стороны несут
                ответственность
                в соответствии с законодательством Республики Узбекистан.
                <br> 10.2. Вся текстовая информация и графические изображения, размещенные в интернет-магазине имеют
                законного
                правообладателя, незаконное использование указанной информации и изображений преследуется в соответствии с
                действующим законодательством.

                <br> 11. Прочие условия.
                <br> 11.1. Все споры, связанные с неисполнением, или ненадлежащим исполнением своих обязательств по
                настоящему
                договору стороны будут стараться решить в ходе переговоров.
                <br> 11.2. В случае не достижения согласия в ходе переговоров, споры будут разрешаться в судебном порядке в
                соответствии с действующим законодательством Республики Узбекистан.
                <br> 11.3. Разделы интернет-магазина, разъясняющие правила и условия, на которые имеются ссылки в Оферте
                являются
                неотъемлемой частью настоящей Оферты.
                <br> 11.4. Акцептируя настоящую оферту, Покупатель выражает согласие и разрешает ООО «OSIYO KABEL»
                обрабатывать
                свои персональные данные, в том числе фамилию, имя, отчество, дату рождения, пол, место работы и должность,
                почтовый адрес; домашний, рабочий, мобильный телефоны, адрес электронной почты, включая сбор,
                систематизацию, хранение, изменение, дополнение, использование, предоставление, распространение, передачу,
                обезличивание и уничтожение персональных данных на территории Республики Узбекистан, а также передачу их
                контрагентам Продавца с целью дальнейшей обработки (включая сбор, систематизацию, хранение, изменение,
                дополнение, использование, предоставление, распространение, передачу, обезличивание и уничтожение
                персональных данных на территории Республики Узбекистан) для проведения исследований, направленных на
                улучшение качества услуг, проведения маркетинговых программ, статистических исследований, а также
                продвижения услуг на рынке путем осуществления прямых контактов с Покупателем с помощью различных средств
                связи, включая, почтовую рассылку, электронную почту, телефон, факсимильную связь, сеть Интернет.
                <br>11.5. Покупатель разрешает Продавцу и контрагентам Продавца обрабатывать персональные данные Покупателя,
                с
                помощью автоматизированных систем управления базами данных, а также иных программных средств, специально
                разработанных по поручению Продавца. Работа с такими системами осуществляется по предписанному оператором
                алгоритму (cбор, систематизация, хранение, изменение, дополнение, использование, предоставление,
                распространение, передача, обезличивание и уничтожение). Используемые способы обработки (включая, но, не
                ограничиваясь): автоматическая сверка почтовых кодов с базой кодов, автоматическая проверка написания
                названий улиц\населенных пунктов, уточнение данных с Покупателем путем телефонной, почтовой связи с
                Покупателем или с помощью контакта через сеть Интернет, сегментация базы по заданным критериям.
                Покупатель соглашается с тем, что, если это необходимо для реализации целей, указанных в настоящей оферте,
                его персональные данные, полученные Продавцом, могут быть переданы третьим лицам, которым Продавец может
                поручить обработку персональных данных Покупателя на основании договора, заключенного с такими лицами, при
                условии соблюдения требований законодательства Республики Узбекистан об обеспечении такими третьими лицами
                конфиденциальности персональных данных и безопасности персональных данных при их обработке.
                При передаче указанных данных Покупателя Продавец предупреждает лиц, получающих персональные данные
                Покупателя, о том, что эти данные являются конфиденциальными и могут быть использованы лишь в целях, для
                которых они сообщены, и требуют от этих лиц соблюдения этого правила.
                <br>11.6. Покупатель вправе запросить у Продавца полную информацию о своих персональных данных, их обработке
                и
                использовании, а также потребовать исключения или исправления/дополнения неверных или неполных персональных
                данных, отправив соответствующий письменный запрос на имя Продавца на почтовый адрес. Данное Покупателем
                согласие на обработку его персональных данных является бессрочным и может быть отозвано посредством
                направления Покупателем письменного заявления в адрес Продавца на почтовый адрес.
            </p>
        </div>
    </div>
</template>

<style scoped>
.privacy-policy {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

p {
    font-size: 13px;
}

.privacy-policy h1 {
    color: black;
    font-weight: 600;
    margin-bottom: 20px;
}

.links a {
    font-size: 13px;
    color: black;
    text-decoration: none;
    transition: .3s;

}

.links {
    margin-bottom: 30px;
}

.links a:hover {
    color: green;
}

.privacy-policy h2 {
    color: black;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 600;
}

.privacy-policy ul {
    list-style-type: disc;
    padding-left: 20px;
    font-size: 13px;
}

.main {
    background-color: white;
}

.container {

    width: 100%;
    background-color: white;
    padding-right: 8px;
    padding-left: 8px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 992px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}</style>